export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    labelIsFake: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    notRequired: {
      type: Boolean,
      default: false,
    },
  },
};
