export default {
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: null,
    },
  },
  computed: {
    markAsFault () {
      return this.hasError || this.errorMsg;
    },
  },
};
