import formIdMixin from './formId.js';
import formInputWidgetPropsMixin from './formInputWidgetProps.js';
import formInputMixin from './formInput.js';
import formLabelMixin from './formLabel.js';
import formErrorMixin from './formError.js';

export default {
  mixins: [formIdMixin, formInputWidgetPropsMixin, formInputMixin, formLabelMixin, formErrorMixin],
  computed: {
    hasPrependContentSlot () {
      return !!this.$scopedSlots['prepend-content'];
    },
    hasPrependSlot () {
      return !!this.$scopedSlots.prepend;
    },
    hasAppendContentSlot () {
      return !!this.$scopedSlots['append-content'];
    },
    hasAppendSlot () {
      return !!this.$scopedSlots.append;
    },
  },
  methods: {
    emitInput (newValue) {
      this.$emit('input', newValue);
    },
  },
};
