import formIdPropsMixin from './formIdProps.js';

export default {
  mixins: [formIdPropsMixin],
  computed: {
    inputId () {
      return this.id ? this.id : this.$id('input');
    },
  },
};
